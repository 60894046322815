<template>
    <div>
        <section class="hero is-medium is-dark has-background">
            <img :src="`https://swissdominoeffect.com/img/headers/${header_image}`" class="is-transparent hero-background" />
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-1 is-size-2-mobile">
                       Standorte
                    </h1>
                </div>
            </div>
        </section>

        <section class="hero is-small is-info">
            <div class="hero-body">
                <div class="container">
                    <router-link :to="{name: 'mieten'}" class="button is-medium is-white is-outlined">
                        Jetzt zum Kostenrechner
                    </router-link>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="container">
                <div class="content is-medium">
                    <p class="subtitle is-4">
                        Unsere vier gut erreichbaren Standorte ermöglichen ein müheloses Abholen der gemieteten Dominosteine. Für jede Vermietung können die Abhol- und Rückgabezeiten nach individueller Planung festgelegt werden.
                        Die verfügbare Anzahl von Dominos an einem Standort kann mit dem <router-link :to="{name: 'mieten'}">Kostenrechner</router-link> eingesehen werden.
                    </p>
                        <div class="columns is-tablet is-multiline is-centered is-vcentered" v-for="(place, idx) in places" :key="place.name">
                            <div class="column" v-if="idx % 2 == 0 && !isMobile()">
                                <img :src="`https://swissdominoeffect.com/img/pickup/${place.image}`" :alt="`Standortbild von ${place.name}`">
                            </div>

                            <div class="column is-6 has-text-centered">
                                <span class="title">{{ place.name }}</span><br>
                                <span class="subtitle">{{ place.street }}</span>
                            </div>
                            <div class="column" v-if="idx % 2 == 1 || isMobile()">
                                <img :src="`https://swissdominoeffect.com/img/pickup/${place.image}`" :alt="`Standortbild von ${place.name}`">
                            </div>
                        </div>
                </div>
            </div>
        </section>

        
        <section class="hero is-small is-info">
            <div class="hero-body">
                <div class="container">
                    <router-link :to="{name: 'mieten'}" class="button is-medium is-white is-outlined">
                        Jetzt zum Kostenrechner
                    </router-link>
                </div>
            </div>
        </section>
    </div>
</template>
<style scoped>
.is-orange {
    background-color: orange;
    color: white;
}
.is-orange .title {
    color: white;
}
</style>
<script>
    import {
        submitTelefonBeratung
    } from '@/webapi.js'
    import Impressionen from '@/components/Impressionen'
    import PortalAd from '@/components/PortalAd'
    export default {
        metaInfo: {
            title: 'Abholstandorte'
        },
        data: function () {
            return {
                header_image: 'kontakt_domino_banner.jpg',
                places: [{
                    name: 'Swiss Domino Effect GmbH',
                    street: 'Tohuebstrasse 2, 8580 Hefenhofen',
                    image: 'Sde.png'
                },
                {
                    name: 's\'Kafi im Grüene',
                    street: 'Weissenbergweg 16, 4802 Strengelbach',
                    image: 'sKafiImGruene.png'
                },
                {
                    name: 'PlaceB Zürich',
                    street: 'Thurgauerstrasse 101A, 8152 Zürich',
                    image: 'PlaceBZuerich.png'
                },
                {
                    name: 'PlaceB Bern',
                    street: 'Weltpoststrasse 5, 3015 Bern',
                    image: 'PlaceBBern.png'
                }
            ]
            }
        },
        methods: {
            isMobile() {
                return this.$isMobile()
            }
        }
        }
</script>